import { useQuery, UseQueryResult } from 'react-query';

import {
  Organization,
  OrganizationSyncStatus,
} from '@dialog/organization-contracts';

import { getOrganization } from 'services/fetch';

import { getGenerateOrganizationKey } from './keys';

export const useGetOrganization = (
  {
    enabled,
    onQuerySuccess,
    onQueryError,
  }: {
    enabled?: boolean;
    onQuerySuccess?: (organization: Organization) => void;
    onQueryError?: () => void;
  } = { enabled: true },
): UseQueryResult<Organization> => {
  const key = getGenerateOrganizationKey();

  const onSuccess = (organization: Organization) => {
    if (onQuerySuccess) {
      onQuerySuccess(organization);
    }
  };

  const onError = () => {
    if (onQueryError) {
      onQueryError();
    }
  };

  const handleRefetchInterval = (data?: Organization) => {
    if (data?.currentSyncStatus === OrganizationSyncStatus.IN_PROGRESS) {
      return 1000;
    }

    return false;
  };

  return useQuery(key, async () => getOrganization(), {
    retry: false,
    enabled,
    onSuccess,
    onError,
    refetchInterval: handleRefetchInterval,
  });
};
